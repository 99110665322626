import React from 'react'

import { Content } from './Flow/Content'
import { Container } from './Layout'

// import * as styles from './styles.module.scss'

export const Flow = () => (
  <Container>
    <Content />
  </Container>
)
