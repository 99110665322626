import React from 'react'

import { Content } from './Barker/Content'

import { Container } from './Layout'

export const Barker = () => (
  <Container>
    <Content />
  </Container>
)
