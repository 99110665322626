import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children }) => (
  <dl className={styles.Section}>{children}</dl>
)

/**
 */
export const Title = ({ children }) => (
  <dt className={styles.Section_Title}>{children}</dt>
)

/**
 */
export const Body = ({ children }) => (
  <dd className={styles.Section_Body}>{children}</dd>
)
/**
 *
 */
export const TextStrong = ({ children }) => (
  <span className={styles.TextStrong}>{children}</span>
)

export const Content = () => (
  <div className={styles.Container}>
    <Container>
      <Title>トータル業務時間を（＝COST）を節約します！</Title>
      <Body>
        Arch−LOG
        では光の物理計算をするレンダリングエンジンの品質に関しても世界最先端の技術を使用していることはもちろんですが、各種プレゼンテーションに他のレンダリングシステムを使用する場合と違い、現実に存在するメーカーのBIM
        情報とレンダリング用の設定を済ませた各製品をダウンロードしてそのままレンダリングに使用するため、今までのパースづくりで行っていたようなテクスチャを調整したり、リアルに見せるための設計作業と関係の無い諸々の不毛で退屈な作業は必要ありません。BIM
        を使用して壁紙を選んだり、各種建材を選んでBIMによって設計図書をつくっていく、その通常の設計行為自体がリアルな絵づくりと同義となるということです。今までは設計作業と顧客プレゼン用（あるいは上司に確認するためでも！）のパースづくりは全く異なる作業でしたが、Arch-LOG
        を使用すれば両者は同時進行で進めることが可能です。貴方が設計検討していると同時にプレゼンの準備はほとんど終わっています。貴方が打ち合わせ前の通常の設計検討を終えた時にプレゼンテーションの絵づくりの為にする必要のあることは、Arch-LOG
        の占有予約システムを利用してそのBIMデータをレンダリングすることだけです！
      </Body>
    </Container>
    <Container>
      <Title>高解像度なレンダリングによる圧倒的なリアリティ！</Title>
      <Body>
        <TextStrong>
          Arch-LOGの占有予約システムを利用するとレンダリング解像度の上限（最大1920×1080）が無くなります。
        </TextStrong>
        貴方の好きなだけ解像度を上げてレンダリングすることが可能になるので、Arch-LOGの物理的に正確な光の計算を行うレンダリングエンジンの表現力を余すことなく利用することができるようになります。設計検討の為にモニタ上で確認するだけでは無く、出力してプレゼンテーション等に利用したい場合にはArch-LOGの占有予約システムにご加入頂くことによって、求められるアウトプットに応じて最適な品質でのリアルタイムレンダリングを利用することができます。是非、高解像度でレンダリング可能なArch-LOGの占有予約システムの圧倒的な表現力をお試し下さい。
      </Body>
    </Container>
    <Container>
      <Title>作成可能な高品質なパースの枚数は無制限</Title>
      <Body>
        Arch-LOGの占有予約システムを利用すれば、リアルタイムで高解像度・高品質なレンダリングが利用可能なので、貴方が取得可能なパースの枚数は予約した時間内においては事実上無制限となります。占有予約システムを利用すると貴方の予約した時間の応じて、クラウドのスーパーコンピューターのパワーが貴方の手に。もうレンダリングに何時間も、何日もかける必要はありません。予めプレゼンテーション前に予約をとっておけば、プレゼンテーションのギリギリまで設計作業を行うことができます。貴方、あるいは顧客の望むあらゆる角度、位置からあらゆる場所のパースが即座に取得可能です。また、場合によってはリアルタイムでレンダリングしながら、顧客にプレゼンテーションを行うことも可能となります。Arch-LOGの占有予約システムは貴方のプレゼンテーション・設計プロセスを今までにないスピード・品質で圧倒的に加速します！
      </Body>
    </Container>
  </div>
)
