import React from 'react'

import * as styles from './styles.module.scss'
import * as Links from '@arch-log/webapp.shared/Links'

/**
 */
export const Article = ({ children }) => (
  <dl className={styles.Article}>{children}</dl>
)

/**
 */
export const ArticleTitle = ({ children }) => (
  <dt className={styles.Article_Title}>{children}</dt>
)

/**
 */
export const ArticleBody = ({ children }) => (
  <dd className={styles.Article_Body}>{children}</dd>
)

/**
 */
export const ParagraphList = ({ children }) => (
  <ol className={styles.ParagraphList}>{children}</ol>
)

/**
 */
export const Paragraph = ({ children }) => (
  <li className={styles.ParagraphList_Item}>{children}</li>
)

/**
 */
export const StepList = ({ children }) => (
  <ol className={styles.StepList}>{children}</ol>
)

/**
 */
export const Step = ({ children }) => (
  <li className={styles.StepList_Step}>{children}</li>
)

export const Content = () => (
  <div className={styles.Container}>
    <Article>
      <ArticleTitle>申し込み</ArticleTitle>
      <ArticleBody>
        下記手順に従って、占有予約システムの利用可能時間を申請して下さい。
        <StepList>
          <Step>
            <p>
              Arch-LOGにログインします。（本システムには前提としてArch-LOGアカウントが必要です。まだアカウントを取得されてない場合は
              <Links.Registration.ToTop>ここ</Links.Registration.ToTop>
              からアカウントを作成し、手順を最初から実行して下さい。）
            </p>
          </Step>
          <Step>
            <p>
              <Links.Reservation.ToTop>
                占有予約システム利用可能時間ページ
              </Links.Reservation.ToTop>
              から、利用可能時間の申請を行います。（
              <Links.Reservation.ToTop>
                占有予約システム利用可能時間ページ
              </Links.Reservation.ToTop>
              はTopページの右上歯車のアイコン → ユーザーアカウント →
              予約/追加申し込み からもアクセス可能です。）
            </p>
          </Step>
          <Step>
            <p>
              「利用可能時間の追加」欄から希望の利用可能時間を選択し、「利用可能時間の追加」ボタンを押します。
            </p>
          </Step>
          <Step>
            <p>
              「利用可能時間追加リクエストが送信されました」と表示されます。
            </p>
          </Step>
          <Step>
            <p>
              担当者より登録メール宛にてご連絡させて頂きますので、それまで少々お待ち下さい。
            </p>
          </Step>
        </StepList>
      </ArticleBody>
    </Article>

    <Article>
      <ArticleTitle>予約について</ArticleTitle>
      <ArticleBody>
        <ParagraphList>
          <Paragraph>
            <Links.Reservation.ToTop>
              占有予約システム利用可能時間ページ
            </Links.Reservation.ToTop>
            にて予約、または予約の取消、延長、変更を行います。
          </Paragraph>
          <Paragraph>
            予約は1時間の単位で行います。実際に使用されたのが1時間に満たない場合でも、1時間分として請求されます。
          </Paragraph>
          <Paragraph>
            現在時刻に対する予約可能な時間枠は次の1時間からとなります。例えば、現在が8：30だとすると次の予約可能な時間は9：00からとなります。
          </Paragraph>
          <Paragraph>
            予約時間の延長は自分の予約の後の時間枠が利用可能な場合には可能です。ただし、占有予約システムの利用開始後は延長ができません。
          </Paragraph>
          <Paragraph>
            予約の取り消しは予約開始時間の3時間前まで可能です。
          </Paragraph>
          <Paragraph>
            予約を行った場合ユーザーにメールが送信されます。
          </Paragraph>
          <Paragraph>
            予約開始時間の10分前にユーザーに対してメールが送信されます。
          </Paragraph>
        </ParagraphList>
      </ArticleBody>
    </Article>

    <Article>
      <ArticleTitle>料金</ArticleTitle>
      <ArticleBody>
        <ParagraphList>
          <Paragraph>
            ￥25,000（税抜き）/1時間　（昼間　7：00～21：00）
          </Paragraph>
          <Paragraph>
            ￥12,500（税抜き）/1時間　（夜間　21：00～7：00）
          </Paragraph>
        </ParagraphList>
      </ArticleBody>
    </Article>
    <Article>
      <ArticleTitle>支払</ArticleTitle>
      <ArticleBody>
        <ParagraphList>
          <Paragraph>毎月末〆、翌日末支払いとさせて頂きます。</Paragraph>
          <Paragraph>
            前月にユーザーが予約、使用した時間別料金の合計を毎月末日に請求させて頂きます。
          </Paragraph>
          <Paragraph>
            予約をしたままで取消を行わないと本システムの利用、未利用に関わらず本システムを予約開始時間から予約終了時間まで利用したものとみなされます。
          </Paragraph>
        </ParagraphList>
      </ArticleBody>
    </Article>

    <Article>
      <ArticleTitle>ご利用にあたっての注意点</ArticleTitle>
      <ArticleBody>
        <ParagraphList>
          <Paragraph>
            占有予約システムを利用するにはローカルのPCでデータを解析した後、データをクラウドサーバーへアップロードする必要がありますので、本システムのユーザーは予約開始時間から終了時間までのすべての時間をリアルタイムレンダリングに使用できるわけではありません。本システムのユーザーは予約開始時間以降にArch-LOG
            Cloud
            Renderingを実行することによりデータを解析し、データをクラウドへアップロードすることができます。データの解析、アップロードにかかる時間は転送されるデータの量、インターネットの接続速度に依存しますので、環境により異なります。
            <br />
            ※予約開始時間前にデータをクラウドサーバーへアップロードできる機能を今後追加予定です。
          </Paragraph>
          <Paragraph>
            クラウドにアップロードされたデータはウィンドウを閉じる毎にクラウドサーバーから消去されます。レンダリングウィンドウを閉じ、もう一度レンダリングを行う場合はデータの解析、アップロードを最初から行う必要がございます。
          </Paragraph>
          <Paragraph>
            インターネットとの接続が切れた場合は Arch-LOG Cloud
            Rendaringは強制終了します。その場合は、同様にデータの解析、アップロードを最初から行う必要がございます。
          </Paragraph>
          <Paragraph>
            占有予約システムを利用される場合には Arch-LOG Cloud Rendaring
            無料版にて予約開始時間より前に
            <strong>
              <font color="red">十分にテスト</font>
            </strong>
            されることをお勧めいたします。また予約時間内において、事前のテストと同等のパフォーマンスを得るためには、テスト時と同一のファイルを使用する必要があります。
          </Paragraph>
        </ParagraphList>
      </ArticleBody>
    </Article>

    <Article>
      <ArticleTitle>解約</ArticleTitle>
      <ArticleBody>
        <ParagraphList>
          <Paragraph>
            占有予約システムを解約するには
            <Links.ToContact
              subject="占有予約システム：解約について"
              body="Arch-LOG 占有予約システムの解約を希望します"
            >
              こちら
            </Links.ToContact>
            よりその旨をメール送信して下さい。
          </Paragraph>
          <Paragraph>
            解約前にすべての予約の取り消しを行って下さい。解約のメールを送信頂いた後、解約の手続きまでには3営業日かかります。予約の取消を行わずに解約の手続き完了までに次の予約時間に差し掛かった場合、その分の占有予約システムの使用料は請求させて頂きます。
          </Paragraph>
        </ParagraphList>
      </ArticleBody>
    </Article>
  </div>
)
