import React from 'react'

import * as Titles from '@arch-log/webapp.shared/Title'

import { Flow } from './Flow'
import { Barker } from './Barker'

import * as Literals from './Literals'
import * as styles from './styles.module.scss'

const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

const Title = ({ children }) => (
  <div className={styles.Title}>
    <Titles.StaticPageTitle>
      <Literals.Title />
    </Titles.StaticPageTitle>
  </div>
)

export const Content = () => (
  <Container>
    <Title />
    <Barker />
    <Flow />
  </Container>
)
