import React from 'react'

import * as styles from './styles.module.scss'

/**
 *
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)
